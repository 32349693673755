/* Environment can be:
  local - local variables on jsonServer - Marko
  docker - local docker - Srdjan
  deploy - for deploy
 */
let environment = 'deploy'

let _dbServer, _socketServer, _jsonServer
switch (environment) {
  case 'local':
    _dbServer = '//localhost:3000'
    _socketServer = ''
    _jsonServer = true
    break

  case 'docker':
    _dbServer = '//localhost:9090'
    _socketServer = '//localhost:5000'
    _jsonServer = false
    break

  case 'deploy':
    _dbServer = '//testboard.paminternationalincus.com/api'
    _socketServer = '//testboard.paminternationalincus.com'
    _jsonServer = false
    break

  default:
    throw 'Environment in dbSettings not defined properly.'
}

export var lsName = 'terror'
export var loginPath = '/auth/signin'
export var tokenRefreshPath = '/auth/refreshtoken'

/********* testiranje lokalno *******************/
/* 
- datum u loadInfo.js
- post param za /auth/refreshtoken 
- post, put datum za /loadNotes (NoteEdit.vue)
*/
export var jsonServer = _jsonServer
export var dbServer = _dbServer
export var socketServer = _socketServer
